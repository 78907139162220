body {
    /*background-image: url("../assets/images/login_background.jpg");*/
}

.loginCard {
    background-color: #F6F6F7;
    width: 30%;
    min-width: 400px;
    margin: 100px auto;
    padding: 2rem;
    border-radius: 1rem;
}